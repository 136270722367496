import { MouseEvent, forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Button, Paper, TableContainer, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { GridCellParams, GridColDef, GridRowParams, GridValueGetterParams, GridState } from '@mui/x-data-grid'
import styled, { AnyStyledComponent } from 'styled-components'
import _ from 'lodash'

import { LoyaltyCardContract } from '@/types/api'
import { dayjs } from '@/utilities/dayjs'
import { getColumnWidth, getStringFilterOperators, isSelection } from '@/utilities/functions'
import DataGridTable, { DataGridTableRef } from '@/components/DataGridTable'
import PrintButton from './PrintButton'
import { selectCustomizationsObj } from '@/models/customizations'
import { selectIsUserRole } from '@/models/authentication'

export interface CardsListRef {
  getSelectedRowIds: () => string[]
}

interface CardsListProps {
  cards?: LoyaltyCardContract[]
  disabled?: boolean
  onItemClick?: (cardId: string) => void
  onCustomizeClick?: (customizationId: string) => void
  onToggleCardClick?: (cardId: string) => void
  onCopyCard?: (cardId: string) => void
}

interface RowData extends LoyaltyCardContract {
  id: string
  index: number
  customizationName: string
}

const TextButton = styled(Button as AnyStyledComponent).attrs({
  type: 'button',
  variant: 'text',
})`
  text-transform: none;
`

const CardsList = forwardRef<CardsListRef, CardsListProps>(
  ({ cards = [], disabled, onItemClick, onCustomizeClick, onToggleCardClick, onCopyCard }, ref) => {
    const [gridWidth, setGridWidth] = useState<number>(0)

    const customizationsObj = useSelector(selectCustomizationsObj)
    const isUserRole = useSelector(selectIsUserRole)

    const dataGridTableRef = useRef<DataGridTableRef>(null)

    const columns = useMemo(() => {
      const data: GridColDef[] = [
        {
          field: 'index',
          headerName: '#',
          headerAlign: 'center',
          align: 'center',
          width: getColumnWidth(gridWidth, 6),
          minWidth: 74,
          disableColumnMenu: true,
          sortable: false,
          filterable: false,
        },
        {
          field: 'email',
          headerName: 'Email',
          headerAlign: 'center',
          align: 'center',
          width: getColumnWidth(gridWidth, isUserRole ? 15 : 11),
          sortable: false,
          filterOperators: getStringFilterOperators(['contains']),
        },
        {
          field: 'description',
          headerName: 'Description',
          headerAlign: 'center',
          align: 'center',
          width: getColumnWidth(gridWidth, isUserRole ? 12 : 8),
          minWidth: isUserRole ? 148 : 99,
          sortable: false,
          filterOperators: getStringFilterOperators(['contains']),
        },
        {
          field: 'companyName',
          headerName: 'Company name',
          headerAlign: 'center',
          align: 'center',
          width: getColumnWidth(gridWidth, 6),
          minWidth: 99,
          disableColumnMenu: true,
          sortable: false,
          filterable: false,
        },
        {
          field: 'companyCode',
          headerName: 'Company code',
          headerAlign: 'center',
          align: 'center',
          width: getColumnWidth(gridWidth, 6),
          minWidth: 99,
          sortable: false,
          filterOperators: getStringFilterOperators(['contains']),
        },
        {
          field: 'code',
          headerName: 'Code',
          headerAlign: 'center',
          align: 'center',
          width: getColumnWidth(gridWidth, 12),
          minWidth: 148,
          sortable: false,
          filterOperators: getStringFilterOperators(['contains']),
        },
        {
          field: 'credits',
          headerName: 'Credits',
          headerAlign: 'center',
          align: 'center',
          width: getColumnWidth(gridWidth, 7),
          minWidth: 86,
          sortable: false,
          filterOperators: getStringFilterOperators(['contains']),
        },
        {
          field: 'isDisabled',
          headerName: 'On/Off',
          headerAlign: 'center',
          align: 'center',
          width: getColumnWidth(gridWidth, 8),
          minWidth: 99,
          sortable: false,
          filterOperators: getStringFilterOperators(['contains']),
          valueGetter: (params: GridValueGetterParams<RowData, boolean>) => {
            return params.value ? 'Disabled' : 'Enabled'
          },
          renderCell: (details: GridCellParams<RowData, string>) => (
            <TextButton
              disabled={details.row.isDisabled || disabled}
              onClick={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation()

                onToggleCardClick?.(details.row.id)
              }}
            >
              {details.value}
            </TextButton>
          ),
        },
        {
          field: 'validTo',
          headerName: 'Valid to',
          headerAlign: 'center',
          align: 'center',
          width: getColumnWidth(gridWidth, 12),
          minWidth: 148,
          sortable: false,
          filterOperators: getStringFilterOperators(['contains']),
        },
        {
          field: 'customizationName',
          headerName: 'Customization name',
          headerAlign: 'center',
          align: 'center',
          width: getColumnWidth(gridWidth, isUserRole ? 12 : 8),
          minWidth: isUserRole ? 148 : 99,
          sortable: false,
          filterOperators: getStringFilterOperators(['contains']),
          renderCell: (details: GridCellParams<RowData>) => {
            const customizationId = details.row.customizationId
            if (!customizationId) {
              return null
            }

            return (
              <TextButton
                disabled={isUserRole}
                onClick={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.stopPropagation()

                  onCustomizeClick?.(customizationId)
                }}
              >
                {details.row.customizationName}
              </TextButton>
            )
          },
        },
        {
          field: 'print',
          align: 'center',
          width: getColumnWidth(gridWidth, 6),
          minWidth: 74,
          disableColumnMenu: true,
          sortable: false,
          filterable: false,
          renderHeader: () => null,
          renderCell: (details: GridCellParams<RowData>) => <PrintButton cardId={details.row.id} />,
        },
        {
          field: 'copy',
          align: 'center',
          width: getColumnWidth(gridWidth, 6),
          minWidth: 74,
          sortable: false,
          filterable: false,
          renderHeader: () => null,
          renderCell: (details: GridCellParams<RowData>) => (
            <Button
              variant="contained"
              size="small"
              onClick={(e) => {
                e.stopPropagation()

                onCopyCard?.(details.row.id)
              }}
            >
              Copy
            </Button>
          ),
        },
      ]

      const filteredData = data.filter(({ field }) => {
        if (!isUserRole) {
          return true
        }

        if (field === 'companyName' || field === 'companyCode') {
          return false
        }

        return true
      })

      return filteredData
    }, [disabled, gridWidth, isUserRole, onCopyCard, onCustomizeClick, onToggleCardClick])

    const rows = useMemo(
      (): RowData[] =>
        cards.map((card, index) => ({
          ...card,
          id: card.loyaltyCardId!,
          index: index + 1,
          code: card.code?.substring(1),
          validTo: dayjs.utc(card.discount?.validTo).format('YYYY-MM-DD HH:mm'),
          customizationName: card.customizationId ? customizationsObj[card.customizationId]?.name ?? '' : '',
        })),
      [cards, customizationsObj],
    )

    const handleRowClick = (details: GridRowParams<RowData>) => {
      if (isSelection()) {
        return
      }

      onItemClick?.(details.row.id)
    }

    const handleStateChange = (state: GridState) => {
      const rowSelectionObj = state.rowSelection.reduce<Record<string, string>>((obj, selectedRowId) => {
        const id = selectedRowId as string

        obj[id] = id

        return obj
      }, {})

      const filteredRowIds: string[] = []
      for (const key in state.visibleRowsLookup) {
        if (!state.visibleRowsLookup[key] || !rowSelectionObj[key]) {
          continue
        }

        filteredRowIds.push(key)
      }

      if (_.isEqual([...state.rowSelection].sort(), [...filteredRowIds].sort())) {
        return
      }

      dataGridTableRef.current?.setRowSelectionModel(filteredRowIds)
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          getSelectedRowIds: () => Array.from(dataGridTableRef.current?.getSelectedRows()?.keys() ?? []) as string[],
        }
      },
      [],
    )

    if (!cards.length) {
      return <Typography>No cards.</Typography>
    }

    return (
      <TableContainer component={Paper}>
        <DataGridTable
          ref={dataGridTableRef}
          rows={rows}
          columns={columns}
          disableColumnSelector
          rowSelection
          checkboxSelection
          disableRowSelectionOnClick
          onTableResize={setGridWidth}
          onRowClick={handleRowClick}
          onStateChange={handleStateChange}
        />
      </TableContainer>
    )
  },
)

export default CardsList
